import { convert } from 'html-to-text';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { editOutputState } from '@note/atoms';

const StatementLine = ({ statementName, index, length = null, hasPunctuations, punctuation, isTemporary = false }) => {
	const editOutput = useRecoilValue(editOutputState);
	const [editedText, setEditedText] = useState(statementName);
	const [punctuationText, setPunctuationText] = useState('');
	const [prefix, setPrefix] = useState('');
	const lastStatementName = useRef(statementName);

	// Check if the statement is manually input
	const isManualInput = index === undefined && hasPunctuations === false && punctuation === undefined;

	// Determine the punctuation based on the statement's position
	const getPunctuation = useCallback(() => {
		if (length === 1 || !hasPunctuations) {
			return '. ';
		} else if (length === 2) {
			const blankSpace = punctuation.TwoPhraseBeforeFinalItem.trim() === 'and' ? ' ' : '';
			return index === 1 ? '. ' : blankSpace + punctuation.TwoPhraseBeforeFinalItem + ' ';
		} else if (length >= 3) {
			if (index === length - 2) {
				const blankSpace = punctuation.ThreePhraseBeforeFinalItem.trim() === 'and' ? ' ' : '';
				return blankSpace + punctuation.ThreePhraseBeforeFinalItem + ' ';
			} else if (index === length - 1) {
				return '. ';
			}
			return punctuation.ThreePhraseSeparation + ' ';
		}
	}, [hasPunctuations, index, length, punctuation]);

	// Determine the suffix for the stem
	const getStemSuffix = useCallback(() => {
		if (hasPunctuations) {
			if (length === 1) {
				return punctuation.OnePhraseSuffix;
			} else if (length === 2) {
				return punctuation.TwoPhraseSuffix;
			} else if (length >= 3) {
				return punctuation.ThreePhraseSuffix;
			}
		}
	}, [hasPunctuations, length, punctuation]);

	const handleChange = useCallback(event => {
		setEditedText(event.target.value);
	}, []);

	const handleManualInputChange = useCallback(
		event => {
			const newText = event.target.value;
			setEditedText(newText.replace(punctuationText, ''));
		},
		[punctuationText]
	);

	// Update the state based on the new statement name, punctuation, and stem suffix
	useEffect(() => {
		if (statementName !== lastStatementName.current && (isManualInput || isTemporary)) {
			setEditedText(statementName || '');
			lastStatementName.current = statementName;
		}

		const punctuation = getPunctuation();
		setPunctuationText(punctuation);
		setPrefix(getStemSuffix() + ' ');
		if (hasPunctuations && index === 0) {
			setPrefix(getStemSuffix() + ' ');
		}
	}, [length, statementName, getPunctuation, getStemSuffix, hasPunctuations, index, isManualInput, isTemporary]);

	// Render the editable or static statement line based on edit mode
	const renderLine = () => {
		return editOutput ? (
			<Editable
				html={editedText}
				onChange={e => (isManualInput ? handleManualInputChange(e) : handleChange(e))}
				tagName="span"
				className="contentEditable"
			/>
		) : (
			<span>{convert(editedText)}</span>
		);
	};

	const hasPeriodPunctuation = editedText?.[editedText.length - 1] === '.';

	// Add space after the statement if needed
	const addSpace = () => {
		if ((length === 1 || (index === undefined && length === null)) && hasPeriodPunctuation) {
			return <> </>;
		}
		return '';
	};

	return (
		<span>
			{hasPunctuations && index === 0 && prefix}
			{renderLine()}
			{!hasPeriodPunctuation && <>{punctuationText}</>}
			{<>{addSpace()}</>}
		</span>
	);
};

export default StatementLine;

const Editable = styled(ContentEditable)`
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
`;
