import { toast } from 'react-toastify';

// A lot of conversions are done to make the copied version look like the version in the note output when pasted in a 3rd party application

// Function to convert a blob URL to a Base64-encoded string
async function blobURLtoBase64(blobURL) {
	const response = await fetch(blobURL); // Fetch the blob from the URL
	const blob = await response.blob(); // Convert the response to a blob
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result); // Resolve with the Base64 string once reading is complete
		reader.onerror = reject; // Reject the promise if there's an error
		reader.readAsDataURL(blob); // Read the blob as a Data URL (Base64 string)
	});
}

// Function to apply inline text alignment styles to cloned HTML elements
const applyInlineStylesToClone = clone => {
	// Function to apply inline styles to <p> tags
	const letterhead = clone.querySelector('.letterhead');
	if (letterhead) {
		letterhead.insertAdjacentHTML('afterend', '<br>');
	}

	// Helper function to apply specific styles and remove classes
	const applyStyles = (selector, style) => {
		clone.querySelectorAll(selector).forEach(p => {
			p.style.textAlign = style; // Apply the text alignment style
			p.classList.remove(selector.substring(1)); // Remove the class that was used to apply the alignment
		});
	};

	// Apply text alignment styles to paragraphs
	applyStyles('.ql-align-center', 'center');
	applyStyles('.ql-align-right', 'right');
	applyStyles('.ql-align-justify', 'justify');
};

// Function to copy HTML content to the clipboard
export const copyToClipboard = async (element, excludeClass) => {
	// Clone the original HTML element
	const clone = element.cloneNode(true);

	// Apply inline styles for text alignment
	applyInlineStylesToClone(clone);

	// Remove elements with a specified class (e.g., elements you don't want to include in the copy)
	const excludedElements = clone.getElementsByClassName(excludeClass);
	while (excludedElements.length > 0) {
		excludedElements[0].remove(); // Remove each excluded element
	}

	// Process images in the cloned element
	const imgElements = clone.querySelectorAll('img');
	for (let img of imgElements) {
		const originalImg = element.querySelector(`img[src='${img.src}']`);
		if (originalImg) {
			// Set the image width and height based on the original element's dimensions
			img.setAttribute('width', originalImg.offsetWidth.toString());
			img.setAttribute('height', originalImg.offsetHeight.toString());
		}
		if (img.src.startsWith('blob:')) {
			// Convert blob URLs to Base64 strings for copying
			img.src = await blobURLtoBase64(img.src);
		}
	}

	// Remove any tips (e.g., tooltips or hints) from the content
	const tips = clone.querySelectorAll('.tips');
	tips.forEach(tip => tip.remove());

	const htmlToCopy = clone.innerHTML; // Get the HTML content to copy

	try {
		// Attempt to write the HTML content to the clipboard
		await navigator.clipboard.write([
			new ClipboardItem({
				'text/html': new Blob([htmlToCopy], { type: 'text/html' }),
			}),
		]);
		toast.success('Copied to clipboard!', { hideProgressBar: true }); // Show success message
	} catch (err) {
		toast.error('Failed to copy content: ' + err, { hideProgressBar: true }); // Show error message if copy fails
	}

	return htmlToCopy;
};

// Function to copy plain text content to the clipboard
export const copyTextToClipboard = async (element, excludeClass) => {
	// Clone the original HTML element
	const clone = element.cloneNode(true);

	// Apply inline styles for text alignment
	applyInlineStylesToClone(clone);

	// Remove elements with a specified class
	const excludedElements = clone.getElementsByClassName(excludeClass);
	while (excludedElements.length > 0) {
		excludedElements[0].remove(); // Remove each excluded element
	}

	// Replace <br> tags with newline characters for text copying
	clone.querySelectorAll('br').forEach(br => {
		br.parentNode.replaceChild(document.createTextNode('\n'), br);
	});

	// Append newline characters after each <p> tag to preserve paragraph breaks in text copying
	clone.querySelectorAll('p').forEach(p => {
		p.appendChild(document.createTextNode('\n'));
	});

	// Remove any tips (e.g., tooltips or hints) from the content
	const tips = clone.querySelectorAll('.tips');
	tips.forEach(tip => tip.remove());

	const textToCopy = clone.textContent || ''; // Get the plain text content to copy

	try {
		// Attempt to write the text content to the clipboard
		await navigator.clipboard.writeText(textToCopy);
		toast.success('Text copied to clipboard!', { hideProgressBar: true }); // Show success message
	} catch (err) {
		toast.error('Failed to copy text: ' + err, { hideProgressBar: true }); // Show error message if copy fails
	}

	return textToCopy;
};
