import Tippy from '@tippyjs/react';
import { Spinner } from 'components/Spinner';
import { useNote } from 'contexts/NoteContext';
import ExportItemModal from 'features/exportItem/exportItemModal';
import MyCustomizations from 'layouts/my-customizations';
import NoteContentHeader from 'layouts/note-output/NoteContentHeader';
import NoteOutput from 'layouts/note-output/NoteOutput';
import Signature from 'layouts/note-output/signature';
import Tips from 'layouts/note-output/tips/tips';
import NoteSelector from 'layouts/note-selector/NoteSelector';
import Tab from 'layouts/note-selector/tab/Tab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FaPencilAlt, FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getHeadingsByNoteId } from 'services/heading';
import { getNoteTypeById } from 'services/noteType';
import { getNotifications } from 'services/notification';
import { getAdminPreferences, getUserPreferences } from 'services/preference';
import { getSectionsByNoteType } from 'services/section';
import { getStatementsByNoteType } from 'services/statement';
import { getStemByNoteType } from 'services/stem';
import { getSubTabsByNoteType } from 'services/subTab';
import { getTabsByNoteId } from 'services/tab';
import styled from 'styled-components';
import { blue } from 'styles/colors';
import { getStatementObject } from 'utils/helper';

import {
	closingCommentsState,
	editOutputState,
	headingsState,
	imagesState,
	introCommentsState, isDemoState,
	isDesignPageState,
	manualInputStatementState,
	notificationsState,
	sectionsState,
	statementsState,
	stemsState,
	subTabsState,
	tabsState,
} from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';
import { usePreference } from '@note/contexts/PreferenceContext';
import MainAppNav from '@note/layouts/nav/main-app/main-app-nav/MainAppNav';
import Letterhead from '@note/layouts/note-output/letterhead/Letterhead';
import AINoteIntroModal from '@note/modals/AINoteIntroModal';
import GPTChatbot from 'components/chatbot/ChatBot';
import TourModal from 'modals/TourModal';
import TermsOfServiceModal from 'modals/TermsOfServiceModal';
import TourManager from 'features/tour/TourManager';
import tourSteps from 'features/tour/tourSteps';
import DemoModal from '@note/modals/DemoModal';

const MainApp = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [showCustomizations, setShowCustomizations] = useState(false);
	const [aiNote, setAiNote] = useState(false);
	const [originalStatements, setOriginalStatements] = useState([]);
	const [originalHeadings, setOriginalHeadings] = useState([]);
	const [isRightPanelCollapsed, setIsRightPanelCollapsed] = useState(false);
	const [showTips, setShowTips] = useState(true);

	const { user, showLetterhead, showCloneModal, setIsOldSafari, isOldSafari, isAdmin } = useContext(AuthContext);
	const { globalPreferences, setPreferences } = usePreference();
	const { activeNoteType, setActiveNoteType, showTermsOfServiceModal } = useNote();
	const [searchParams] = useSearchParams();
	const [editoutput, setEditOutput] = useRecoilState(editOutputState);
	// eslint-disable-next-line no-unused-vars
	const [headings, setHeadings] = useRecoilState(headingsState);
	const setStatements = useSetRecoilState(statementsState);
	const setStems = useSetRecoilState(stemsState);
	const setNotifications = useSetRecoilState(notificationsState);
	const setTabs = useSetRecoilState(tabsState);
	const setSubTabs = useSetRecoilState(subTabsState);
	const setSections = useSetRecoilState(sectionsState);
	const setIsDesignPage = useSetRecoilState(isDesignPageState);
	const setIsDemoPage = useSetRecoilState(isDemoState);
	const [closingComment, setClosingComment] = useRecoilState(closingCommentsState);
	const [introComment, setIntroComment] = useRecoilState(introCommentsState);
	const setManualInputs = useSetRecoilState(manualInputStatementState);
	const [images, setImages] = useRecoilState(imagesState);

	const userRef = useRef(user);
	const imagesRef = useRef(images);

	const closeTips = () => setShowTips(false);

	useEffect(() => {
		imagesRef.current = images;
	}, [images]);

	// Detect if the user is using an old version of Safari
	useEffect(() => {
		const userAgent = navigator.userAgent;
		const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

		if (isSafari) {
			const match = userAgent.match(/Version\/([0-9._]+)/);

			if (match && match[1]) {
				const version = parseFloat(match[1]);

				setIsOldSafari(version < 15);
			}
		}
	}, [setIsOldSafari]);

	const toggleRightPanel = () => {
		setIsRightPanelCollapsed(!isRightPanelCollapsed);
	};

	// Clears the current note and resets to the initial state
	const clearNote = useCallback(() => {
		setIntroComment('');
		setClosingComment('');
		setStatements(originalStatements);
		setHeadings(originalHeadings);
		setManualInputs([]);
		if (imagesRef.current) {
			const updatedImages = imagesRef.current.map(img => ({
				...img,
				isSelected: false,
			}));
			setImages(updatedImages);
		}
	}, [
		setIntroComment,
		setClosingComment,
		setStatements,
		originalStatements,
		setHeadings,
		originalHeadings,
		setManualInputs,
		setImages,
	]);

	const storeFetchedStatements = useCallback(
		statements => {
			const data = statements?.data.map(statement => getStatementObject(statement));
			setStatements(data);
			setOriginalStatements(data);
		},
		[setStatements]
	);

	// Clears the note when the component mounts
	useEffect(() => {
		let isMounted = true;
		if (isMounted) clearNote();
		return () => {
			isMounted = false;
		};
	}, [clearNote]);

	useEffect(() => {
		const userAgent = navigator.userAgent;
		const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

		if (isSafari) {
			const match = userAgent.match(/Version\/([0-9._]+)/);

			if (match && match[1]) {
				const version = parseFloat(match[1]);

				setIsOldSafari(version < 15);
			}
		}
	}, [setIsOldSafari]);

	useEffect(() => clearNote(), [clearNote]);

	// Warns the user if they try to leave the page while editing
	useEffect(() => {
		window.onbeforeunload = function () {
			return 'Are you sure you want to leave?';
		};
		return () => (window.onbeforeunload = undefined);
	}, []);

	// Fetches all necessary data to display the note when the component mounts
	useEffect(() => {
		if (!userRef) {
			return null;
		}
		setIsDesignPage(false);
		setShowCustomizations(false);

		const id = searchParams.get('id');

		Promise.all([
			getTabsByNoteId(id),
			getHeadingsByNoteId(id),
			getUserPreferences(),
			getAdminPreferences(id),
			getNoteTypeById(id),
			getSubTabsByNoteType(id),
			getSectionsByNoteType(id),
			getStatementsByNoteType(id),
			getStemByNoteType(id),
			getNotifications(),
		])
			.then(
				([tabs, headings, userPrefs, adminPrefs, noteTypes, subTabs, sections, statements, stems, notifications]) => {
					// Set fetched data into the relevant state
					setAiNote(noteTypes?.data.is_ai_note);
					setTabs(tabs?.data);
					const headingsData =
						headings?.data.length > 0
							? headings?.data
									.map(heading => ({ ...heading, content: '' }))
									.sort((a, b) => a.order_index - b.order_index)
							: [];
					setHeadings(headingsData);
					setOriginalHeadings(headingsData);
					setPreferences(userPrefs?.data);
					if (noteTypes?.data.user === userRef.id) {
						setPreferences(userPrefs?.data);
					} else {
						// For default note type we need admin preferences
						setPreferences([
							...adminPrefs?.data.filter(pref => pref.note_type !== null || pref.subtab !== null),
							...userPrefs?.data,
						]);
					}
					if (noteTypes) {
						setActiveNoteType(noteTypes.data);
						setIsDemoPage(!isAdmin && noteTypes.data.name === "Demo Note");
					}
					setSubTabs(subTabs?.data.filter(subTab => subTab?.type !== null));
					setSections(sections?.data);
					storeFetchedStatements(statements);
					setStems(stems?.data.map(st => ({ ...st, editedValue: st.value })));
					setNotifications(notifications?.data);
					setIsLoading(false);
				}
			)
			.catch(error => {
				console.error('🚀 ~ file: MainApp.js ~ line 115 ~ useEffect ~ error', error);
				setIsLoading(false);
			});
	}, [
		searchParams,
		setActiveNoteType,
		setNotifications,
		setHeadings,
		setIsDesignPage,
		setPreferences,
		setSections,
		setStatements,
		setStems,
		setSubTabs,
		setTabs,
		storeFetchedStatements,
		setIsDemoPage,
    isAdmin
	]);

	// Component to handle collapsing/expanding the right panel
	const CollapsePanel = () => {
		return (
			<ToggleButton onClick={toggleRightPanel} collapsed={isRightPanelCollapsed}>
				{isRightPanelCollapsed ? (
					<Tippy content={'Expand panel'}>
						<FaAngleDoubleLeft style={{ fontSize: 25, marginLeft: -3 }} />
					</Tippy>
				) : (
					<Tippy content={'Collapse panel'}>
						<IconContainer>
							<StyledIcon />
						</IconContainer>
					</Tippy>
				)}
			</ToggleButton>
		);
	};

	return (
		<div className="main-app-container">
			<TourManager steps={tourSteps}>
				<MainAppNav
					isLoading={isLoading}
					clearNote={clearNote}
					showCustomizations={showCustomizations}
					toggleCustomizations={setShowCustomizations}
				/>
				<main className="main-body">
					<GPTChatbot />
					{!isLoading ? (
						<>
							<div className="left-pane" style={{ width: isRightPanelCollapsed ? '95%' : '60%' }}>
								<div className="left-pane-wrapper" style={{ background: isAdmin ? 'pink' : '' }}>
									<NoteSelector tabComponent={Tab} />
								</div>
							</div>
							<div className="right-pane" style={{ width: isRightPanelCollapsed ? '5%' : '40%' }}>
								{CollapsePanel()}
								<div style={{ height: '100%' }}>
									{showCustomizations ? (
										<MyCustomizations
											show={setShowCustomizations}
											style={{ display: isRightPanelCollapsed ? 'none' : 'flex' }}
										/>
									) : (
										<div
											className="right-pane-wrapper"
											style={{ background: aiNote ? '#E8DDEB' : isAdmin ? '#8CEEED' : '' }}
										>
											<div style={{ display: isRightPanelCollapsed ? 'none' : 'flex', flexDirection: 'column' }}>
												<NoteContentHeader
													isAiNote={aiNote}
													clearNote={clearNote}
													introComment={introComment}
													setIntroComment={setIntroComment}
													closingComment={closingComment}
													setClosingComment={setClosingComment}
												/>
												<Note id="note" className="note">
													<Tippy
														content={
															isOldSafari ? 'Update your version of Safari in order to use the feature' : 'Quick edit'
														}
													>
														<TippyContainer>
															<IconWrapper
																active={editoutput}
																className="edit-output"
																disabled={isOldSafari}
																onClick={() => {
																	if (!isOldSafari) {
																		setEditOutput(!editoutput);
																	}
																}}
															>
																<FaPencilAlt />
															</IconWrapper>
														</TippyContainer>
													</Tippy>
													{showLetterhead && user?.letterhead !== null && user?.letterhead !== undefined && (
														<div style={{ padding: '20px 10px 0 10px' }}>
															<Letterhead letterhead={user.letterhead} />
														</div>
													)}
													<div className="note-output">
														<NoteOutput
															isAiNote={aiNote}
															introComment={introComment}
															setIntroComment={setIntroComment}
															closingComment={closingComment}
															setClosingComment={setClosingComment}
														/>
														<br />
													</div>
													<div
														contentEditable={false}
														className="signature"
														id="signature"
														style={{ paddingLeft: '10px', paddingRight: '10px', flexGrow: 1 }}
													>
														<Signature />
													</div>
													{showTips && globalPreferences.Tips === 'Show' && (
														<TipsWrapper className="tips">
															<Tips close={() => closeTips()} />
														</TipsWrapper>
													)}
												</Note>
											</div>
										</div>
									)}
								</div>
							</div>
						</>
					) : (
						<Spinner />
					)}
					{user?.can_use_chatgpt && activeNoteType?.is_ai_plus_note && !activeNoteType?.is_tour && <AINoteIntroModal />}
					<TermsOfServiceModal show={showTermsOfServiceModal} />
					<ExportItemModal
						show={showCloneModal.show}
						isSubTab={showCloneModal.isSubTab}
						exportItemId={showCloneModal.id}
					/>
				</main>
				{activeNoteType?.is_tour ? <TourModal /> : activeNoteType?.name === 'Demo Note' && <DemoModal />}
			</TourManager>
		</div>
	);
};

export default MainApp;

const Note = styled.div`
	position: relative;
`;

const TippyContainer = styled.span`
	z-index: 50;
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
`;

const IconWrapper = styled.div`
	position: relative;
	background: transparent;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	cursor: pointer;
	color: ${props => (props.disabled ? 'gray' : props.active ? `${blue}` : 'gray')};
	cursor: ${props => (props.disabled ? 'default' : 'pointer')};

	&:hover {
		border: ${props => (!props.disabled && props.active ? `2px solid ${blue}` : 'none')};
		color: ${props => (!props.disabled ? `${blue}` : 'gray')};
	}
`;

const ToggleButton = styled.button`
	position: absolute;
	border: 3px solid lightgray;
	color: lightgray;
	background: transparent;
	border-radius: 50%;
	width: ${props => (props.collapsed ? '3rem' : '2rem')};
	height: ${props => (props.collapsed ? '3rem' : '2rem')};
	z-index: 200;
	bottom: ${props => (props.collapsed ? 'unset' : '1.5rem')};
	left: -0.5rem;
	top: ${props => (props.collapsed ? '50%' : 'unset')};
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 40px;
	height: 40px;
`;

const StyledIcon = styled(FaAngleDoubleRight)`
	font-size: 20px;
	transform: translate(-13px, -9px);

	@media (min--moz-device-pixel-ratio: 0) {
		transform: translate(-11px, -9px);
	}
`;

const TipsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 85%;
	padding: 1rem;
`;
