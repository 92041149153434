import React from 'react';
import { postSupport } from '../../services/aiEdit.js';

// Handles the sending and receiving to and from the Chatbot API
const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const handleMessage = async (message) => {
    // Create a loading message
    const loadingMessage = createChatBotMessage("Just a moment while I analyze that...");
    setState(prev => ({
      ...prev,
      messages: [...prev.messages, loadingMessage],
    }));

    try {
      const response = await postSupport(message);
      const responseMessage = response[response.length - 1].message
      setState(prev => {
        // Filter out the loading message from the messages array
        const newMessages = prev?.messages?.filter(msg => msg !== loadingMessage);
        // Create a new message with the bot's response
        const botMessage = createChatBotMessage(responseMessage);
        return {
          ...prev,
          messages: [...newMessages, botMessage],
        };
      });
    } catch (error) {
      setState(prev => {
        // Filter out the loading message from the messages array
        const newMessages = prev?.messages?.filter(msg => msg !== loadingMessage);
        // Create an error message if something goes wrong
        const errorMessage = createChatBotMessage("Something went wrong. Please try again.");
        return {
          ...prev,
          messages: [...newMessages, errorMessage],
        };
      });
      // Log the error for debugging purposes
      console.error("Error in ActionProvider:", error);
    }
  };

  return (
    <div>
      {/* Pass the handleMessage function to each child component */}
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
