import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {ThemeButton} from "@note/components/ThemeButton";

function RequestDemo() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [consent, setConsent] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(true);

  const sendDemoRequest = (email) => {
    if (email)
      fetch(`/accounts/demo/sub/${email}/`, {method: "POST"}).then(response => {
        if (response.status === 200) {
          console.log('Demo request sent successfully');
          setShowRequestModal(false);
        } else {
          console.error('Failed to send demo request');
        }
      }).catch(serverError => {
        console.error('Failed to send demo request', serverError);
        setError('Failed to send demo request. Please try again shortly.');
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setEmailError(false);
    console.log('Captured email:', email);
    if (!email) {
      setEmailError(true);
      return;
    }
    if (!consent) {
      setError('Check "I consent to receive an email [...]" to proceed.');
      return;
    }
    sendDemoRequest(email);
  };

  return (
    <>
      <Modal
        className="demoModal"
        show={showRequestModal}
        backdrop="static"
        scrollable
        centered
        size="lg"
      >
        <form onSubmit={handleSubmit} className="p-3">
          <Modal.Header>
            <Modal.Title>Note Designer Demo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Just enter your email address and we’ll send your link to our demonstration note.</p>

            {/* Email Input with Conditional Red Border */}
            <div className="mb-3">
              <label htmlFor="userEmail" className="form-label">
                Email:
              </label>
              <input
                type="email"
                id="userEmail"
                className={`form-control ${emailError ? 'border border-danger' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Consent Checkbox (Italic) */}
            <div className="form-check mb-3">
              <input
                type="checkbox"
                id="consentCheckbox"
                className="form-check-input "
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
                style={{outline: "2px solid #1e5180"}}
              />
              <label
                htmlFor="consentCheckbox"
                className="form-check-label ms-2 fst-italic"
              >
                <small>
                  I consent to receive an email with a link to access a demo version of the Note Designer app,
                  and to receive follow-up emails and promotional offers from Note Designer Inc.
                </small>
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {error && <div className="text-danger me-auto">{error}</div>}
            <ThemeButton label="Send" size="lg" color="green" onClick={handleSubmit}/>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        className="demoModal"
        show={!showRequestModal}
        backdrop="static"
        scrollable
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Note Designer Demo</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <br/>
          <p>Success! You will receive an email with your link to the demo!</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RequestDemo;