import React from 'react';
import { Form } from 'react-bootstrap';

const HeadingText = ({ heading, onChange, spellCheck }) => {
	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<Form.Control
			onChange={handleChange}
			type="text"
			value={heading.content}
			spellCheck={spellCheck}
			className={heading.name === 'Name' ? 'tour-heading-input' : ''}
			placeholder={
				heading.placeholder && heading.placeholder.length > 0 ? heading.placeholder : 'Enter ' + heading.name
			}
		/>
	);
};

export default React.memo(HeadingText);
