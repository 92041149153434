import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';
import VideoLinkButton from 'layouts/nav/VideoLinkButton';
import NewNoteModal from 'layouts/note-type/NewNoteModal';
import { useNote } from 'contexts/NoteContext';
// import { useTour } from 'hooks';
import LearnMoreModal from 'modals/LearnMoreModal';
import ManageNoteTypeModal from 'layouts/note-type/manage-notetype/ManageNoteTypeModal';
import { AuthContext } from '@note/contexts/AuthContext';

const Splash = () => {
	const { user } = useContext(AuthContext);
	const [showModal, setShowModal] = useState();
	const [showMoreInfo, setShowMoreIndo] = useState();
	const [showManageNoteTypeModal, setShowManageNoteTypeModal] = useState(false);

	const { activeNoteType } = useNote();

	const openModal = useCallback(() => setShowModal(true), []);
	const closeModal = useCallback(() => setShowModal(false), []);

	const openMoreInfo = useCallback(() => setShowMoreIndo(true), []);
	const closeMoreInfo = useCallback(() => setShowMoreIndo(false), []);

	const openManageNoteTypeModal = useCallback(() => setShowManageNoteTypeModal(true), []);
	const closeManageNoteTypeModal = useCallback(() => setShowManageNoteTypeModal(false), []);

	const handleDashboardClick = useCallback(() => {
		window.open('/dashboard/', '_blank');
	}, []);

	const { logoutClearStorage } = useContext(AuthContext);
	const handleLogout = () => logoutClearStorage(activeNoteType?.id);

	return (
		<>
			<Container className="splash-container h-100 align-items-center d-flex justify-content-center">
				<Row>
					<Col className="text-center">
						<div>
							<img src={user?.site?.main_logo} alt="Logo" />
						</div>
						<div className="my-2 d-inline-block text-center">
							<div style={{ marginBottom: '3rem', marginTop: '1rem' }}>
								{user?.first_name ? <h2>{`Welcome, ${user.first_name}!`}</h2> : <h2>Welcome!</h2>}
							</div>
							<ButtonWrapper>
								<VideoLinkButton fullWidth={false} size="lg" label="Welcome video" />
							</ButtonWrapper>
							<ButtonWrapper className="start-new-note-btn">
								<ThemeButton label="Start your notes" size="lg" onClick={openModal} />
							</ButtonWrapper>
							<ButtonWrapper>
								<ThemeButton label="Dashboard" color="yellow" size="lg" onClick={handleDashboardClick} />
							</ButtonWrapper>
							<ButtonWrapper>
								<ThemeButton
									label="Logout"
									color="red"
									size="lg"
									onClick={() => window.confirm('Are you sure you want to log out?') && handleLogout()}
								/>
							</ButtonWrapper>
							<DashboardMessage>
								<i>
									(If you wish to revert to the older version of Note Designer for the time being, you can access that
									through your dashboard. To learn more about the free upgrade{' '}
									<LearnMoreLink onClick={openMoreInfo}> click here</LearnMoreLink>)
								</i>
							</DashboardMessage>
						</div>
					</Col>
				</Row>
			</Container>
			<NewNoteModal openManageNoteTypeModal={openManageNoteTypeModal} show={showModal} handleClose={closeModal} />
			<LearnMoreModal show={showMoreInfo} handleClose={closeMoreInfo} />
			<ManageNoteTypeModal show={showManageNoteTypeModal} handleClose={closeManageNoteTypeModal} />
		</>
	);
};

export default Splash;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 8px;
`;

const DashboardMessage = styled.p`
	width: 450px;
	opacity: 0.7;
	margin-top: 20px;
`;

const LearnMoreLink = styled.span`
	display: inline-block;
	text-decoration: underline;
	color: #0d6efd;
	cursor: pointer;
`;
