import { PreferenceTypes } from 'utils/enum';

// This is used to build the preferences menu
export const globalPreferenceGroups = [
	{
		title: 'Ai rewrite',
		subTitle: 'Do you want to enable the AI Rewrite feature?',
		preferences: [
			{
				key: PreferenceTypes.AI_REWRITE,
				options: ['Enabled', 'Disabled'],
			},
		],
	},
	{
		title: 'Terminology',
		subTitle: 'Which terms do you prefer (current selections are highlighted)?',
		preferences: [
			{
				key: PreferenceTypes.TERMINOLOGY_CLIENT,
				options: ['client', 'patient', 'person', 'student', 'youth', 'Veteran', 'service member'],
			},
			{
				key: PreferenceTypes.TERMINOLOGY_PSYCHODYNAMIC,
				options: ['psychodynamic', 'psychoanalytic'],
			},
			{
				key: PreferenceTypes.TERMINOLOGY_BEHAVIOR,
				options: ['behavior', 'behaviour'],
			},
		],
	},
	{
		title: 'Opening heading',
		subTitle: 'Do you wish to include a heading "Session Note" or "Progress Note"?',
		preferences: [
			{
				key: PreferenceTypes.OPENING_HEADING,
				options: ['Session Note', 'Progress Note', 'no heading'],
			},
		],
	},
	{
		title: 'Hide the subtab categories by default',
		preferences: [
			{
				key: PreferenceTypes.COLLAPSE_SUBTABS,
				options: ['On', 'Off'],
			},
		],
	},
	{
		title: 'Spacing Between Options',
		subTitle: 'Set your spacing between Options:',
		preferences: [
			{
				key: PreferenceTypes.OPTION_SPACING,
				options: ['0', '1', '2', '3', '4', '5'],
			},
		],
	},
	{
		title: 'Printed-Note Font Style',
		subTitle: 'Set your Printed-Note Font Style:',
		preferences: [
			{
				key: PreferenceTypes.PRINT_FONT_STYLE,
				options: ['Arial', 'Times', 'Courier', 'DejaVu'],
			},
		],
	},
	{
		title: 'Printed-Note Font Size',
		subTitle: 'Set your Printed-Note Font Size:',
		preferences: [
			{
				key: PreferenceTypes.PRINT_FONT_SIZE,
				options: ['12', '14', '16', '18', '20'],
			},
		],
	},
	{
		title: 'Color Opacity of Selected Statements',
		subTitle: 'Set color opacity of selected statements:',
		preferences: [
			{
				key: PreferenceTypes.COLOR_OPACITY,
				options: ['0.1', '0.3', '0.5', '0.7', '0.9'],
			},
		],
	},
	{
		title: 'Date Format',
		subTitle: 'Set date format of your headings:',
		preferences: [
			{
				key: PreferenceTypes.DATE_FORMAT,
				options: [
					'September 23, 1939',
					'09/23/1939',
					'23/09/1939',
					'1939-09-23',
					'Saturday, 23 September, 1939',
					'Saturday, September 23, 1939',
					'23 September, 1939',
				],
			},
		],
	},
	{
		title: 'Tab Colors',
		subTitle: 'Set the colors of your tabs',
		preferences: [
			{
				key: PreferenceTypes.TABS_COLORS,
				options: ['Purple', 'Mixed'],
			},
		],
	},
	{
		title: 'Tab Breaks',
		subTitle: 'Set breaks in your notes between each tab',
		preferences: [
			{
				key: PreferenceTypes.TAB_BREAK,
				options: ['Hide breaks', 'Show breaks'],
			},
		],
	},
	{
		title: 'Tab Title',
		subTitle: 'Choose to show or hide title for your tab',
		preferences: [
			{
				key: PreferenceTypes.TAB_HEADING,
				options: ['Hide title', 'Show title'],
			},
		],
	},
	{
		title: 'Subtab Title',
		subTitle: 'Choose to show or hide title for your subtab',
		preferences: [
			{
				key: PreferenceTypes.SUBTAB_HEADING,
				options: ['Hide title', 'Show title'],
			},
		],
	},
	{
		title: 'Subtab Breaks',
		subTitle: 'Set breaks in your notes between each subtab',
		preferences: [
			{
				key: PreferenceTypes.SUBTAB_BREAK,
				options: ['Hide breaks', 'Show breaks'],
			},
		],
	},
	{
		title: 'Button size',
		subTitle: 'Choose the size of your cursor icons',
		preferences: [
			{
				key: PreferenceTypes.BUTTON_SIZE,
				options: ['Smaller', 'Larger'],
			},
		],
	},
	{
		title: 'Images',
		subTitle: 'Enable images usage in your note:',
		preferences: [
			{
				key: PreferenceTypes.USE_IMAGES,
				options: ['Hide images', 'Show images'],
			},
		],
	},
	{
		title: 'Sound effects',
		subTitle: 'Enable sound effects in the application (not compatible with Safari versions below 15)',
		preferences: [
			{
				key: PreferenceTypes.SOUND_EFFECTS,
				options: ['Enabled', 'Disabled'],
			},
		],
	},
	{
		title: 'Onscreen Tips',
		preferences: [
			{
				key: PreferenceTypes.TIPS,
				options: ['Show', 'Hide'],
			},
		],
	},
];

export const notePreferenceGroups = [
	{
		title: 'Show/Hide title',
		subTitle: 'Choose whether you want to show your note title or not',
		preferences: [
			{
				key: PreferenceTypes.NOTE_TITLE,
				options: ['Hide title', 'Show title'],
			},
		],
	},
	{
		title: 'Note title',
		subTitle: 'Enter the title you want for this note-type',
		preferences: [
			{
				key: PreferenceTypes.NOTE_TITLE_VALUE,
				isInputType: true,
				options: [],
			},
		],
	},
	{
		title: 'Tab title',
		subTitle: 'Choose whether you want to show tab title or not',
		preferences: [
			{
				key: PreferenceTypes.TAB_HEADING,
				options: ['Hide title', 'Show title'],
			},
		],
	},
	{
		title: 'Tab breaks',
		subTitle: 'Choose whether you want to show tab breaks or not',
		preferences: [
			{
				key: PreferenceTypes.TAB_BREAK,
				options: ['Hide breaks', 'Show breaks'],
			},
		],
	},
	{
		title: 'Subtab Title',
		subTitle: 'Choose to show or hide title for your subtab',
		preferences: [
			{
				key: PreferenceTypes.SUBTAB_HEADING,
				options: ['Hide title', 'Show title'],
			},
		],
	},
	{
		title: 'Subtab Breaks',
		subTitle: 'Set breaks in your notes between each subtab',
		preferences: [
			{
				key: PreferenceTypes.SUBTAB_BREAK,
				options: ['Hide breaks', 'Show breaks'],
			},
		],
	},
	{
		title: 'Opening heading',
		subTitle: 'Do you wish to include a heading "Session Note" or "Progress Note"?',
		preferences: [
			{
				key: PreferenceTypes.OPENING_HEADING,
				options: ['Session Note', 'Progress Note', 'no heading'],
			},
		],
	},
];

export const signaturePreferenceGroups = [
	{
		title: 'Signature date',
		subTitle: 'Choose to hide or show date with your signature',
		preferences: [
			{
				key: PreferenceTypes.SIGNATURE_DATE,
				options: ['Show date', 'Hide date'],
			},
		],
	},
	{
		title: 'Date format',
		subTitle: 'Set date format of your headings:',
		preferences: [
			{
				key: PreferenceTypes.SIGNATURE_DATE_FORMAT,
				options: [
					'September 23, 1939',
					'09/23/1939',
					'23/09/1939',
					'1939-09-23',
					'Saturday, 23 September, 1939',
					'Saturday, September 23, 1939',
					'23 September, 1939',
				],
			},
		],
	},
	{
		title: 'Signature time',
		subTitle: 'Choose to hide or show time with your signature',
		preferences: [
			{
				key: PreferenceTypes.SIGNATURE_TIME,
				options: ['Show time', 'Hide time'],
			},
		],
	},
	{
		title: 'Signature start line',
		subTitle: 'Enter any text you wish to show above the date',
		preferences: [
			{
				key: PreferenceTypes.SIGNATURE_START_LINE,
				isInputType: true,
				options: [],
			},
		],
	},
	{
		title: 'Signature end line',
		subTitle: 'Enter any text you wish to show below the date',
		preferences: [
			{
				key: PreferenceTypes.SIGNATURE_END_LINE,
				isInputType: true,
				options: [],
			},
		],
	},
];
