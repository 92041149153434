import React, { useState, useEffect } from 'react';
import Chatbot, { createChatBotMessage } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import { FaQuestion } from "react-icons/fa";
import chatLogo from 'assets/sophie.png';
import { showSupport } from '../../services/aiEdit.js';
import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';
import { ChatbotContainer, ChatbotToggle, ChatLogo, ChatbotHeaderTitle, ChatbotHeaderText, CloseButton, HeaderContainer } from './ChatBot.styles';
import { isDemoState } from '@note/atoms';
import { useRecoilValue } from 'recoil';

// AI supported chatbot available from the "?" icon in the main app

const GPTChatbot = () => {
    const isDemo =  useRecoilValue(isDemoState);
    const [isSupportCalled, setIsSupportCalled] = useState(false);
    const [chatbotVisible, setChatbotVisible] = useState(false);
    const botName = "Sophie";

    // Toggle the visibility of the chatbot
    const toggleChatbotVisibility = () => {
        setChatbotVisible(!chatbotVisible);
    };

    // Custom header for the chatbot
    const CustomHeader = () => {
        return (
            <HeaderContainer>
                <ChatbotHeaderText>
                    <ChatbotHeaderTitle>Sophie the helpful chatbot</ChatbotHeaderTitle>
                </ChatbotHeaderText>
                <CloseButton onClick={() => toggleChatbotVisibility()} style={{ top: 10, right: 10 }} />
            </HeaderContainer>
        );
    };

    // Call support service to initialize the chatbot
    useEffect(() => {
        if (!isSupportCalled) {
            showSupport();
            setIsSupportCalled(true);
        }
    }, []);

    // Configuration for the chatbot
    const chatbotConfig = {
        botName: botName,
        initialMessages: [
            createChatBotMessage(`Hi I'm ${botName}`),
            createChatBotMessage("Ask me anything, I'll do my best."),
            createChatBotMessage("If I can't answer, contact us at info@notedesigner.com."),
        ],
        customComponents: {
            header: () => <CustomHeader />,
            botAvatar: (props) => <ChatLogo src={chatLogo} alt="Logo" />,
        }
    };

    if (isDemo) return null;

    return (
        <div>
            <ChatbotToggle
                onClick={toggleChatbotVisibility}
                className="chatbot-toggle-button"
                role="button"
                tabIndex={0}
            >
                <FaQuestion style={{ fontSize: '2.5rem' }} />
            </ChatbotToggle>
            <ChatbotContainer style={{ display: chatbotVisible ? 'block' : 'none' }}>
                <Chatbot
                    config={chatbotConfig}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            </ChatbotContainer>
        </div>
    );
};

export default GPTChatbot;
