import React from 'react';
import { Form } from 'react-bootstrap';
import FontChoice from './font-choice';
import FontSizeChoice from './font-size-choice';
import './text-format.scss';

// Lines for chosing options of the letterhead
const LetterheadLine = ({
	label,
	value,
	font,
	fonts,
	fontSize,
	fontSizes,
	onFontSizeChange,
	isBold,
	isItalic,
	onValueChange,
	onFontChange,
	onBoldToggle,
	onItalicToggle,
	placeholder,
}) => {
	const handleBoldToggle = () => {
		onBoldToggle(!isBold);
	};

	const handleItalicToggle = () => {
		onItalicToggle(!isItalic);
	};

	return (
		<Form.Group controlId={`form${label}`}>
			<div className="d-flex">
				<Form.Control
					type="text"
					value={value}
					placeholder={placeholder}
					spellCheck={!label.toLowerCase().includes('name')}
					onChange={onValueChange}
					style={{
						maxWidth: '45%',
						fontFamily: font,
						fontWeight: isBold ? 'bold' : 'normal',
						fontStyle: isItalic ? 'italic' : 'normal',
						maxHeight: '2rem',
						marginRight: '2rem',
					}}
				/>
				<div className={`d-flex flex-column ml-3 choice-format`}>
					<FontChoice selectedFont={font} fonts={fonts} onFontChange={onFontChange} />
					<div className={`d-flex choice-format`}>
						<button className={`text-format ${isBold ? 'active' : ''}`} type="button" onClick={handleBoldToggle}>
							<b>Bold</b>
						</button>
						<button className={`text-format ${isItalic ? 'active' : ''}`} type="button" onClick={handleItalicToggle}>
							<i>Italic</i>
						</button>
						<FontSizeChoice selectedFontSizes={fontSize} fontSizes={fontSizes} onFontSizeChange={onFontSizeChange} />
					</div>
				</div>
			</div>
		</Form.Group>
	);
};

export default LetterheadLine;
