import { defaultGlobalPreferences } from 'constants';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { PreferenceTypes } from 'utils/enum';

import { useNote } from './NoteContext';

// These are the preferences set by the user from the preferences modal
const PreferenceContext = React.createContext();
export const usePreference = () => useContext(PreferenceContext);

export const PreferenceProvider = ({ children }) => {
	const [preferences, setPreferences] = useState([]);
	const { activeNoteType } = useNote();

	// Get the preference value for a given key and sub-tab
	const getPreferenceValueBySubTab = (key, subTab) => {
		if (isEmpty(preferences)) {
			return defaultGlobalPreferences[key];
		}
		const preference = preferences?.find(pref => pref.key === key && pref.subtab === subTab.id);
		return preference && preference !== -1 ? preference.value : defaultGlobalPreferences[key];
	};

	// Get the preference value for a given key and section
	const getPreferenceValueBySection = (key, section) => {
		if (isEmpty(preferences)) {
			return defaultGlobalPreferences[key];
		}
		const preference = preferences?.find(pref => pref.key === key && pref.section === section.id);
		return preference && preference !== -1 ? preference.value : defaultGlobalPreferences[key];
	};

	// Get the preference value for a given key and note type
	const getPreferenceValueByNoteType = (key, noteTypeId) => {
		if (isEmpty(preferences)) {
			return defaultGlobalPreferences[key];
		}
		const preference = preferences?.find(
			pref =>
				pref.key === key &&
				pref.note_type === noteTypeId &&
				pref.subtab === null &&
				pref.section === null &&
				pref.tab === null
		);
		return preference && preference !== -1 ? preference.value : defaultGlobalPreferences[key];
	};

	// Get the global preference value for a given key
	const getGlobalPreferenceValue = useCallback(
		key => {
			if (isEmpty(preferences)) {
				return defaultGlobalPreferences[key];
			}
			const preference = preferences?.find(pref => pref !== undefined && pref.key === key && pref.note_type === null);

			return preference && preference !== -1 ? preference.value : defaultGlobalPreferences[key];
		},
		[preferences]
	);

	// Get the multi-level preference value for a given key and note type
	const getMultiLevelPreferenceValueForNoteType = useCallback(
		key => {
			let prefValue = getGlobalPreferenceValue(key);
			if (activeNoteType && preferences) {
				const notePreference = preferences?.find(pref => pref.key === key && pref.note_type === activeNoteType.id);
				if (notePreference) prefValue = notePreference.value;
			}
			return prefValue;
		},
		[preferences, activeNoteType, getGlobalPreferenceValue]
	);

	// Memoize global preferences to avoid unnecessary recalculations
	const globalPreferences = useMemo(() => {
		return {
			CollapseSubtabs: getGlobalPreferenceValue(PreferenceTypes.COLLAPSE_SUBTABS),
			OptionsSpacing: getGlobalPreferenceValue(PreferenceTypes.OPTION_SPACING),
			OpeningHeading: getGlobalPreferenceValue(PreferenceTypes.OPENING_HEADING),
			FontSize: getGlobalPreferenceValue(PreferenceTypes.PRINT_FONT_SIZE),
			FontStyle: getGlobalPreferenceValue(PreferenceTypes.PRINT_FONT_STYLE),
			DateFormat: getGlobalPreferenceValue(PreferenceTypes.DATE_FORMAT),
			ColorOpacity: getGlobalPreferenceValue(PreferenceTypes.COLOR_OPACITY),
			SubTabBreak: getGlobalPreferenceValue(PreferenceTypes.SUBTAB_BREAK),
			SubTabHeading: getGlobalPreferenceValue(PreferenceTypes.SUBTAB_HEADING),
			ClientTerminology: getGlobalPreferenceValue(PreferenceTypes.TERMINOLOGY_CLIENT),
			BehaviorTerminology: getGlobalPreferenceValue(PreferenceTypes.TERMINOLOGY_BEHAVIOR),
			PsychodynamicTerminology: getGlobalPreferenceValue(PreferenceTypes.TERMINOLOGY_PSYCHODYNAMIC),
			ButtonSize: getGlobalPreferenceValue(PreferenceTypes.BUTTON_SIZE),
			SignatureDateFormat: getGlobalPreferenceValue(PreferenceTypes.SIGNATURE_DATE_FORMAT),
			SignatureDate: getGlobalPreferenceValue(PreferenceTypes.SIGNATURE_DATE),
			SignatureTime: getGlobalPreferenceValue(PreferenceTypes.SIGNATURE_TIME),
			SignatureStartLine: getGlobalPreferenceValue(PreferenceTypes.SIGNATURE_START_LINE),
			SignatureEndLine: getGlobalPreferenceValue(PreferenceTypes.SIGNATURE_END_LINE),
			TabBreak: getGlobalPreferenceValue(PreferenceTypes.TAB_BREAK),
			TabHeading: getGlobalPreferenceValue(PreferenceTypes.TAB_HEADING),
			useImages: getGlobalPreferenceValue(PreferenceTypes.USE_IMAGES),
			SoundEffects: getGlobalPreferenceValue(PreferenceTypes.SOUND_EFFECTS),
			TabsColors: getGlobalPreferenceValue(PreferenceTypes.TABS_COLORS),
			Tips: getGlobalPreferenceValue(PreferenceTypes.TIPS),
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preferences, getGlobalPreferenceValue]);

	// Memoize multi-level preferences to avoid unnecessary recalculations
	const multiLevelPreferences = useMemo(() => {
		return {
			openingHeading: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.OPENING_HEADING),
			subTabBreak: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.SUBTAB_BREAK),
			tabBreak: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.TAB_BREAK),
			tabTitle: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.TAB_HEADING),
			subTabTitle: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.SUBTAB_HEADING),
			clientTerminology: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.TERMINOLOGY_CLIENT),
			behaviorTerminology: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.TERMINOLOGY_BEHAVIOR),
			psychodynamicTerminology: getMultiLevelPreferenceValueForNoteType(PreferenceTypes.TERMINOLOGY_PSYCHODYNAMIC),
		};
	}, [getMultiLevelPreferenceValueForNoteType]);

	const value = {
		preferences,
		globalPreferences,
		multiLevelPreferences,
		setPreferences,
		getPreferenceValueBySection,
		getPreferenceValueBySubTab,
		getPreferenceValueByNoteType,
	};

	return <PreferenceContext.Provider value={value}>{children}</PreferenceContext.Provider>;
};
